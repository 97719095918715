import dayjs from "dayjs";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { parse } from "node-html-parser";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Parser from 'react-html-parser';
import Layout from '../components/layout';
import Seo from "../components/seo";
import LogoImage from '../images/thumnail_blog_default.png';
import { isSSR, updateBlogContent } from '../utils/utils';

import '../styles/cus.css';
// const isSSR = () => typeof window === 'undefined' || !window.document;

function Post({ data, description, lang, meta, title, pageContext }) {
  const thePost = data.THE_POST;
  const recentBlogs = data.OTHER_POSTS;
  // get tag names of recent posts
  const tagsSet = new Set();
  recentBlogs.nodes.forEach(p => {
    p.tags.nodes.forEach(c => tagsSet.add(c.name));
  });
  const tags = Array.from(tagsSet);
  // Shuffle array
  const randomPosts = data.RANDOM_POSTS.nodes.sort(() => 0.5 - Math.random());

  // Get sub-array of first n elements after shuffled
  let selectedRandomPosts = randomPosts.slice(0, 5);
  ////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Process Images loading lazy
  //
  ////////////////////////////////////////////////////////////////////////////////////////////
  const processContent = (content) => {
    if (!content) return content;
    const DOMAIN_WP = 'https://wp.inventiv.org'

    const postContentParser = parse(content);

    /**
     * Lazy loading images
     */
    const imgTags = postContentParser.querySelectorAll("img");
    if (imgTags && imgTags.length) {
      for (const img of imgTags) {
        // https://stackoverflow.com/questions/77744344/is-it-okay-to-use-both-fetchpriority-high-and-loading-eager-in-img-tag
        img.setAttribute("loading", "lazy");

        img.setAttribute("fetchpriority", "low");
      }
    }

    /**
     * Process WP domain
     */
    const aTags = postContentParser.querySelectorAll("a");
    if (aTags && aTags.length) {
      for (const blog of aTags) {
        let href = blog.getAttribute("href");
        if (href && href.startsWith(DOMAIN_WP)) {
          blog.setAttribute("href", href.replace(DOMAIN_WP, ""));
        }
      }
    }


    return postContentParser.toString();
  };

  thePost.content = processContent(thePost.content);
  ////////////////////////////////////////////////////////////////////////////////////////////
  const [updatedBlogContent, setUpdatedBlogContent] = useState([]);
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
    setUpdatedBlogContent(updateBlogContent(thePost.content, selectedRandomPosts));
  }, []);

  return (
    <Layout>
      <Seo title={`${thePost.title}`} canonical={`/blog${thePost.uri.slice(0, -1)} `} seo={thePost.seo} />
      <div class="sub-banner main-banner">
        <div class="container">
          <div class="breadcrumb-area">
            <h1>Blog Details</h1>

          </div>
        </div>
      </div>

      <div class="blog-section content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="blog blog-2 mb-40">
                <div class="blog-photo">
                  {
                    thePost.featuredImage?.node?.mediaItemUrl ?
                      <img src={thePost.featuredImage?.node.mediaItemUrl} alt="Blog Image"
                        class="img-fluid w-100 mb-5"></img>
                      : null
                  }

                </div>
                <div class="detail">
                  <h2 class="title-2">
                    <a href="#" aria-label={thePost.title}>{thePost.title}</a>
                  </h2>
                  <div class="post-meta clearfix">
                    <span><a href="/" aria-label="Go Home"><i class="fa fa-user"></i></a>INVENTIV.ORG</span>
                  </div>
                  <div className="card-text post-content">{Parser(isSSR() ? thePost.content : updatedBlogContent)}</div>
                  <br />
                  <div class="row clearfix tags-socal-box">
                    <div class="col-lg-7 col-md-7 col-sm-7">
                      {
                        tags && tags.length ?
                          <div class="popular-tags">
                            <h4 class="title-3">Tags</h4>
                            <ul>
                              {
                                tags.map((t) => (
                                  <li><a href={`/tag/${t.toLowerCase().replace(/\s/g, '-')}`}>{t}</a></li>
                                ))
                              }
                            </ul>
                          </div>
                          : null
                      }
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5">
                      <div class="social-list">
                        <h4 class="title-3">Share</h4>
                        <ul>
                          <li>
                            <a href="#" class="facebook" aria-label="Facebook">
                              <i class="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" class="twitter" aria-label="Twitter">
                              <i class="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" class="google" aria-label="Google">
                              <i class="fa fa-google"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" class="linkedin" aria-label="Linkedin">
                              <i class="fa fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" class="rss" aria-label="Rss">
                              <i class="fa fa-rss"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="sidebar mb-30">
                <div class="widget categories">
                  <h3 class="sidebar-title">Categories</h3>
                  <ul>
                    {
                      thePost.categories.nodes.map((t) => (
                        <li><a href={'/blogs/' + t.name.toLowerCase().replace(/\s/g, '-')}>{t.name}</a></li>
                      ))
                    }
                  </ul>
                </div>
                <div class="widget popular-postes">
                  <h3 class="sidebar-title">Popular postes</h3>
                  {
                    recentBlogs.nodes.map((node, index) => (
                      <div class="media mb-4">
                        <a class="pr-3" href="portfolio-details.html">
                          {node.featuredImage?.node.localFile.childImageSharp ?
                            <GatsbyImage className="h-100"
                              image={
                                node.featuredImage?.node.localFile.childImageSharp?.gatsbyImageData
                              }
                              alt="blog"
                            />
                            : node.featuredImage?.node.mediaItemUrl ? <div class="h-100"><img src={node.featuredImage?.node.mediaItemUrl} class="h-100" /></div>
                              : <div class="h-100"><img src={LogoImage} class="h-100" /></div>
                          }
                        </a>
                        <div class="media-body align-self-center">
                          <p><a href={"/blog/" + node.slug}>{node.title}</a></p>
                          <p><i class="fa fa-calendar"></i>{dayjs(data.date).format('MMM DD, YYYY')}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
                {
                  tags && tags.length ?
                    <div class="widget popular-tags clearfix">
                      <h3 class="sidebar-title">Popular Tags</h3>
                      <ul class="tags">
                        {
                          tags.map((t) => (
                            <li><a href={`/tag/${t.toLowerCase().replace(/\s/g, '-')}`}>{t}</a></li>
                          ))
                        }
                      </ul>
                    </div>
                    : null
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Post.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Post.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export const query = graphql`
  query($neId: String, $slug: String) {
    OTHER_POSTS: allWpPost(limit: 4, filter: {id: {ne: $neId}}) {
      nodes {
        title
        slug
        date
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    RANDOM_POSTS: allWpPost(
      limit: 5, 
      filter: {tags: {nodes: {elemMatch: {name: {ne: "Patent Review"}}}}},
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        title
        slug
      }
    }
    THE_POST: wpPost(slug: { eq: $slug }) {
      seo {
        canonical
        focuskw
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
      date(formatString: "LL")
      content
      id
      uri
      title
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }`
export default Post;
